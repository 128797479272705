const getDomainURL = () => {
  if (window.location.host.indexOf("localhost") !== -1) {
    return "https://atlastest.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging8.iqiglobal.com") !== -1
  ) {
    return "https://apistaging.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging7.iqiglobal.com") !== -1
  ) {
    return "https://apitest.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging6.iqiglobal.com") !== -1
  ) {
    return "https://apitest.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging5.iqiglobal.com") !== -1
  ) {
    return "https://atlastest.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging4.iqiglobal.com") !== -1
  ) {
    return "https://apistaging.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging3.iqiglobal.com") !== -1
  ) {
    return "https://apistaging.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging2.iqiglobal.com") !== -1
  ) {
    return "https://apitest3.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging1.iqiglobal.com") !== -1
  ) {
    return "https://apitest.iqiglobal.com/api/v1";
  } else if (
    window.location.host.indexOf("atlasfestaging.iqiglobal.com") !== -1
  ) {
    return "https://atlastest.iqiglobal.com/api/v1";
  } else if (window.location.host.indexOf("atlas.iqiglobal.com") !== -1) {
    return "https://api.iqiglobal.com/api/v1";
  } else {
    return "https://atlaspg.iqiglobal.com/api/v1";
  }
};

export default getDomainURL;
